import axios from 'axios'
import endpointCalculator from '@/services/endpoint_calculator.js'
import { getValue, setValue, removeKey } from "@/services/persistence"

const endpoint = endpointCalculator()

export const ensureSession = () => {
  // TODO
}

export const cleanExpired = () => {
  // TODO
}

export const getRemoteSession = async () => {
  try {
    const response = await axios.get(`${endpoint}/flask/session`)
    const data = response.data
    let token = data.session
    if (token == "null") token = null
    return token
  } catch(err) {
  }

  return null
}

export const setRemoteSession = async (token) => {
  try {
    const response = await axios.get(`${endpoint}/flask/store-session?token=${token}`)
    const data = response.data
    return data.session
  } catch(err) {
  }

  return null
}
